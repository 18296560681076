<template>
  <div>
    <div class="top-box">

      <div class="left-box">
        <div>{{$t('car_list.rim.show_page.technology_9')}}</div>
        <button
          style="width:156px;height:50px;font-size: 18px;background-color:#3d79fd;color:#fff;margin-top:34px;"
          @click="$emit('buy')">
          {{$t('car_list.buy_now')}}
        </button>
        <div style="margin-top:60px">{{ $t('footer.contact') }}</div>
        <img style="margin-top:20px" src="https://www.achencybe.com/img/pc/detail/rim/6-9-1.png">
      </div>
      <img style="width:100%;max-width:800px;padding-top:20px;margin-left:80px;" src="https://www.achencybe.com/img/pc/detail/rim/6-9-2.png">
    </div>

    <div class="footer-nav">
      <!--      <img src="../../assets/images/other/bottom_package_m3_2.png" alt="">-->
      <div class="nav-item" v-for="(item,idx) in navList" :key="item.text">
        <img :src="item.img" alt="">
        <a v-if="idx === 0"  @click="$router.push({name:'FooterDetail',query:{type:'AC'}})">{{ $t(item.text) }}</a>
        <div v-if="idx !== 0">{{ $t(item.text) }}</div>
      </div>
    </div>
    <div style="padding:59px 0;font-size:12px;color:#8b8b8b;text-align: left;width:800px;margin:0 auto;">
      {{ $t('footer.remark_title') }}
      <br />

      <br />
      {{ $t('footer.remark_1') }}
      <br />
      {{ $t('footer.remark_2') }}
      <br />
      {{ $t('footer.remark_3') }}
      <br />
      {{ $t('footer.remark_4') }}
      <br />
      {{ $t('footer.remark_5') }}
      <br />
      {{ $t('footer.remark_6') }}
      <br />
      {{ $t('footer.remark_7') }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    img: String
  },
  data() {
    return {
      footerList: ["footer.product", "footer.gift", "footer.serial_number", "footer.instructions"],
      navList: [
        {
          img: "https://www.achencybe.com/img/pc/detail/footer_nav_1.png",
          text: "footer.support"
        },
        {
          img: "https://www.achencybe.com/img/pc/detail/footer_nav_2.png",
          text: "footer.free_shipping"
        },
        {
          img: "https://www.achencybe.com/img/pc/detail/footer_nav_4.png",
          text: "footer.installation"
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
.top-box{
  background-color: #191718;
  padding:110px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .left-box{
    font-size:18px;
    line-height: 32px;
  }
}

.footer-nav {
  width: 100%;
  background-color: #262626;
  display: flex;
  justify-content: center;
  padding: 40px 0;

  .nav-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #64beff;

    img {
      width: 105px;
    }

    a {

      color: #64beff;
      text-decoration: underline;
    }
  }

  .nav-item + .nav-item {
    margin-left: 100px;
  }
}
</style>