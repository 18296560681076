var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("page", [
    _c(
      "div",
      { staticClass: "home-page" },
      [
        _c("zui-header", { attrs: { hasLine: true } }),
        _c(
          "div",
          {
            staticClass: "service",
            staticStyle: { padding: "0", "margin-top": "77px" },
          },
          [
            _c("div", { staticClass: "frist-m3-area" }, [
              _c("div", { staticClass: "frist-m3-area frist-m3-area-top" }, [
                _c("img", {
                  staticClass: "banner-img",
                  attrs: {
                    src: "https://www.achencybe.com/img/pc/detail/rim/6-1.jpg",
                  },
                }),
                _c("div", { staticClass: "frist-m3-area-title" }, [
                  _c("div", [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("car_list.rim.suitable"))),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "frist-m3-area-sub-title" }, [
                  _c("div", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("car_list.rim.sub_1")))]),
                  ]),
                  _c("div", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("car_list.rim.sub_2")))]),
                  ]),
                  _c("div", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("car_list.rim.sub_3")))]),
                  ]),
                  _c("div", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("car_list.rim.sub_4")))]),
                  ]),
                  _c("div", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("car_list.rim.sub_5")))]),
                  ]),
                ]),
                _c("div", { staticClass: "frist-m3-area-buy-area" }, [
                  _c(
                    "div",
                    {
                      staticClass: "frist-m3-area-buy-button",
                      on: {
                        click: function ($event) {
                          return _vm.goToBuy()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("car_list.buy_now")) + " ")]
                  ),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "frist-m3-area frist-m3-area-show",
                  staticStyle: {
                    "padding-top": "0",
                    "background-color": "#0c0c0c",
                  },
                },
                [
                  _c("img", {
                    staticClass: "frist-m3-area-top-bottom-more",
                    attrs: {
                      src: "https://www.achencybe.com/img/pc/others/LearnMore.png",
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "frist-m3-area-show-area-div" }, [
                    _c(
                      "div",
                      { staticClass: "frist-m3-area-show-area-div-top-box" },
                      [
                        _c("div", { staticClass: "logo-box" }, [
                          _c("img", {
                            staticClass: "logo",
                            attrs: {
                              src: "https://www.achencybe.com/img/pc/detail/rim/logo.png",
                            },
                          }),
                        ]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "left",
                              "font-weight": "100",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("car_list.rim.show_page.technology_1")
                                )
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("car_list.rim.show_page.technology_2")
                                )
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("car_list.rim.show_page.technology_3")
                                )
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("car_list.rim.show_page.technology_4")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "frist-m3-area frist-m3-area-goodlist" },
                [
                  _c(
                    "div",
                    { staticClass: "features-box" },
                    [
                      _c("features", {
                        attrs: {
                          "sub-title":
                            "Achen&Cybe标准套装包含车辆前部扰流饰板、车辆尾部扩散器饰板、前部进气格栅饰板以及尾部扰流板。",
                          list: _vm.list,
                          titleList: _vm.titleList,
                          language: this.$i18n.locale,
                          "is-rim": _vm.isRim,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "font-size-padding50 font-size-12",
                      staticStyle: { width: "100%", "font-weight": "100" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("car_list.rim.show_page.technology_5"))
                      ),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("car_list.rim.show_page.technology_6"))
                      ),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("car_list.rim.show_page.technology_7"))
                      ),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("car_list.rim.show_page.technology_8")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "background-color": "#0c0c0c",
                        "padding-top": "150px",
                      },
                    },
                    [
                      _c("introduce", {
                        attrs: { introduceList: _vm.introduceList },
                      }),
                      _c("detail-footer", {
                        attrs: {
                          img: "https://www.achencybe.com/img/pc/detail/m4/2-9.png",
                        },
                        on: {
                          buy: function ($event) {
                            return _vm.goToBuy()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticStyle: { clear: "both" } }),
          ]
        ),
        _c("zui-footer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }