<template>
  <div>
    <div class="top-box">
      <div class="title-list" :style="{ marginBottom: isRim ? '110px': ''}">
        <div class="title-item" v-for="(item,idx) in titleList" :key="idx">
          <img :src="item.img" alt=""/>
          <h2 style="white-space: nowrap">
            {{ $t(item.title) }}
          </h2>
          <div style="height:168px">
            <p>
              {{ $t(item.content) }}
            </p>

          </div>
          <div class="price-box" v-if="language === 'zh'" :style="{ marginTop: item.a ? '' : '-2px' }">
            RMB<span style="font-family: 'ELEMENT-ICONS'"> {{
              item.price === 0 ? "待公告" : formatPrice(item.price)
            }}<span v-if="item.priceUnit"
                    style="font-size:20px;margin-left:2px;font-weight: bold">{{ item.priceUnit }}</span></span>
          </div>
          <div class="price-box" v-else :style="{ marginTop: item.a ? '' : '-2px' }">
            <span v-if="item.priceUnit"
                  style="font-size:20px;margin-left:2px;font-weight: bold">{{ "From "}} </span> $<span style="font-family: 'ELEMENT-ICONS'"> {{
              item.price_dollar === 0 ? "待公告" : formatPrice(item.price_dollar)
            }}</span>
          </div>
        </div>
      </div>
      <div v-if="isRim === true" class="rim-split">
        <h2 style="white-space: nowrap;height: 93px;line-height: 93px;">
          {{$t('car_list.rim.show_page.painting.title')}}
        </h2>
      </div>
      <div class="list-con" :style="{marginTop : isRim ? '15px': '75px'}">
        <div class="list-item" v-for="(item, idx) in list" :key="idx">
          <img :src="item.img" alt=""/>
          <div style="white-space: nowrap;" v-if="typeof item.color !== 'string'">
            <span class="color-radio" :style="fixTwoColor(item.color[0],item.color[1])"></span>{{ $t(item.title) }}
          </div>
          <div style="white-space: nowrap;" v-else-if="typeof item.color === 'string'">
            <span class="color-radio" :style="{background: item.color, }"></span>{{ $t(item.title) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    list: {
      type: Array
    },
    titleList: Array,
    isThe3: Boolean,
    isRim: Boolean,
    language: {
      type: String,
      default: 'zh',
    }
  },
  methods: {
    formatPrice(val) {
      if(val == null || val === 'null'){
        val = '0'
      }
      console.log(val)
      if (val) {
        return val.toString().replace(/\d+/, function (n) {
          return n.replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        });
      }
      return "";
    },
    fixTwoColor(first, other) {
      console.log(first)
      console.log(other)
      let background ="linear-gradient(to bottom, " + first + " 55%, " + other + " 45%)"
      return { background: background}
    }
  }
};
</script>

<style scoped lang="scss">

.rim-split {
  border-top: 1px solid #cccccc;
  padding-top: 35px;
}

.top-box {
  padding-top: 40px;

  h1 {
    font-size: 30px !important;
    margin: 12px 0 !important;
  }

  .title-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .title-item {
      width: 49.5%;
      margin: 0 auto;

      img {
        width: 100%;
        margin-bottom: 40px;
      }

      p {
        width: 100%;
        max-width: 370px;
        margin: 12px auto 0;
        min-height: 104px;
        font-weight: 100;
      }

      .price-box {
        font-size: 26px;
      }


    }
  }


  .list-con {
    margin-top: 75px;
    padding: 0 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .list-item {
      width: 49.5%;

      img {
        width: 100%;
        margin: 20px 0;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: center;

        .color-radio {
          transform: rotate(45deg);
          display: inline-block;
          width: 18px;
          height: 18px;
          background-color: #fff;
          border-radius: 18px;
          margin-right: 10px;
          margin-top: 2px;
        }
      }

    }
  }
}

@media screen and (max-width: 500px) {
  .top-box {
    .sub-title {
      width: 80%;
      font-size: 12px;
      margin: 0 auto;
    }

    .list-con {
      padding: 0;

      .list-item {
        p {
          width: 80%;
          line-height: 1.2;
          font-weight: 100;
          min-height: 104px;
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .top-box {
    .sub-title {
      width: 80%;
      font-size: 12px;
      margin: 0 auto;
    }

    .list-con {
      padding: 0;

      .list-item {
        p {
          width: 80%;
          line-height: 1.2;
          font-weight: 100;
        }
      }
    }
  }
}
</style>