<template>
  <page>
    <div class="home-page">
      <zui-header :hasLine="true" />
      <div class="service" style="padding: 0; margin-top: 77px">
        <div class="frist-m3-area">
          <div class="frist-m3-area frist-m3-area-top">
            <img class="banner-img" src="https://www.achencybe.com/img/pc/detail/rim/6-1.jpg" />

            <div class="frist-m3-area-title">
              <div><span>{{ $t('car_list.rim.suitable') }}</span></div>
            </div>
            <div class="frist-m3-area-sub-title">
              <div><span>{{ $t('car_list.rim.sub_1') }}</span></div>
              <div><span>{{ $t('car_list.rim.sub_2') }}</span></div>
              <div><span>{{ $t('car_list.rim.sub_3') }}</span></div>
              <div><span>{{ $t('car_list.rim.sub_4') }}</span></div>
              <div><span>{{ $t('car_list.rim.sub_5') }}</span></div>
            </div>
            <div class="frist-m3-area-buy-area">
              <div class="frist-m3-area-buy-button" @click="goToBuy()">
                {{$t('car_list.buy_now')}}
              </div>
            </div>
          </div>
          <div
            class="frist-m3-area frist-m3-area-show"
            style="padding-top: 0; background-color: #0c0c0c"
          >
            <img
              class="frist-m3-area-top-bottom-more"
              src="https://www.achencybe.com/img/pc/others/LearnMore.png"
              alt=""
            />
            <div class="frist-m3-area-show-area-div">
              <!--              <img src="../../../assets/images/other/M3_M4_S.png" style="width:80%;margin:110px 0 50px">-->
              <div class="frist-m3-area-show-area-div-top-box">
                <div class="logo-box">
                  <img class="logo" src="https://www.achencybe.com/img/pc/detail/rim/logo.png" />
                </div>
                <div style="text-align: left; font-weight: 100;">
                  {{$t('car_list.rim.show_page.technology_1')}}<br />
                  {{$t('car_list.rim.show_page.technology_2')}}<br />
                  {{$t('car_list.rim.show_page.technology_3')}}<br />
                  {{$t('car_list.rim.show_page.technology_4')}}
                </div>
              </div>
            </div>
          </div>
          <div class="frist-m3-area frist-m3-area-goodlist">
            <div class="features-box">
              <features
                sub-title="Achen&Cybe标准套装包含车辆前部扰流饰板、车辆尾部扩散器饰板、前部进气格栅饰板以及尾部扰流板。"
                :list="list"
                :titleList="titleList"
                :language="this.$i18n.locale"
                :is-rim="isRim"
              />
            </div>

            <div
              class="font-size-padding50 font-size-12"
              style="width: 100%; font-weight: 100"
            >
              {{$t('car_list.rim.show_page.technology_5')}}<br />
              {{$t('car_list.rim.show_page.technology_6')}}<br />
              {{$t('car_list.rim.show_page.technology_7')}}<br />
              {{$t('car_list.rim.show_page.technology_8')}}
            </div>
            <div style="background-color: #0c0c0c; padding-top: 150px">
              <introduce :introduceList="introduceList" />


              <detail-footer
                :img="'https://www.achencybe.com/img/pc/detail/m4/2-9.png'"
                @buy="goToBuy()"
              />
            </div>
          </div>
        </div>
        <div style="clear: both"></div>
      </div>
      <zui-footer />
    </div>
  </page>
</template>
<script>
import "../../less/home-page.less";
import "../../less/rim-show.less";
import Page from "../../../components/Page";
import ZuiHeader from "../../../components/Header";
import ZuiFooter from "../../../components/Footer";
import features from "./features";
import introduce from "@/components/introduce";
import DetailFooter from "./detail-footer";

export default {
  components: { Page, ZuiHeader, ZuiFooter, features, introduce, DetailFooter },
  data() {
    return {
      bannerHeight: "1050px",
      titleList:{
        img: "https://www.achencybe.com/img/pc/detail/rim/6_00.jpg",
        title: "BB01轻量化轮辋组件",
        content:
            "锻造工艺的轻量化轮辋，在强度更高的同时让你的车辆簧下重量大幅减轻。标准套装为磨砂枪灰色涂装，如果你对涂装有特殊需要，Achen&Cybe Studio定制服务将尽可能满足你的需求。",
        a: "",
        price: 12299,
        priceUnit:"起"
      },
      isRim: true,
      list: [
        {
          img: "https://www.achencybe.com/img/pc/detail/rim/6_01.jpg",
          title:  'car_list.rim.show_page.painting.item1',
          color: ["#050505","#404040"]
        },
        {
          img: "https://www.achencybe.com/img/pc/detail/rim/6_02.jpg",
          title:  'car_list.rim.show_page.painting.item2',
          color:"#4B4A4A",
        },
        {
          img: "https://www.achencybe.com/img/pc/detail/rim/6_03.jpg",
          title:  'car_list.rim.show_page.painting.item3',
          color:"#A9A9AB",
        },
        {
          img: "https://www.achencybe.com/img/pc/detail/rim/6_04.jpg",
          title:  'car_list.rim.show_page.painting.item4',
          color:"#000000",
        },
      ],
      introduceList: [
        {
          img: "https://www.achencybe.com/img/pc/detail/rim/6-6-1.jpg",
          title: "car_list.rim.show_page.introduces.item1.title",
          content: "car_list.rim.show_page.introduces.item1.content",
          contImg: "",
          subContent: "",
        },
        {
          img: "https://www.achencybe.com/img/pc/detail/rim/6-6-2.jpg",
          title: "car_list.rim.show_page.introduces.item2.title",
          content: "car_list.rim.show_page.introduces.item2.content",
          contImg: "",
          subContent: "",
        },
        {
          img: "https://www.achencybe.com/img/pc/detail/rim/6-6-3.jpg",
          title: "car_list.rim.show_page.introduces.item3.title",
          content: "car_list.rim.show_page.introduces.item3.content",
          subContent: "",
        },
        {
          img: "https://www.achencybe.com/img/pc/detail/rim/6-6-4.jpg",
          title: "car_list.rim.show_page.introduces.item4.title",
          content: "car_list.rim.show_page.introduces.item4.content",
          contImg: "",
          subContent: "",
        },
      ],
    };
  },
  mounted() {
    this.height = window.innerHeight + "px";
    this.bannerHeight = (651 / 1280) * (window.innerWidth >= 1200 ? window.innerWidth : 1200) + "px";
    window.addEventListener("resize", () => {
      this.$nextTick(() => {
        this.height = window.innerHeight - 120 + "px";
        this.bannerHeight = (651 / 1280) * (window.innerWidth >= 1200 ? window.innerWidth : 1200) + "px";
      });
    });
    this.getCarDetail();
  },
  methods: {
    goToBuy: function () {
      this.$router.push({
        path: "/pages/public/OrderPage",
        query: { cartype: "rim" },
      });
    },
    goToLearn: function () {
      this.$router.push({ path: "/pages/bmw3/BMW3ShowPage" });
    },
    goToCarEPlus: function () {
      this.$router.push({ path: "/pages/public/CarEPlusPage" });
    },
    getCarDetail() {
      this.$api
        .getCarDetail({
          carModelName: "锻造轮辋",
        })
        .then((res) => {
          if (res.code === 0) {
            res.data.listAccessoriesList.sort((a, b) => {
              return a.partsIndex - b.partsIndex;
            });
            this.titleList = [
              {
                img: "https://www.achencybe.com/img/pc/detail/rim/6_00_1.jpg",
                title: "car_list.rim.show_page.items.item1.title",
                content:"car_list.rim.show_page.items.item1.content",
                a: "",
                price: res.data.listAccessoriesList[0].partsPrice,
                price_dollar: res.data.listAccessoriesList[0].partsPriceDollar,
                priceUnit:"起"
              },
              {
                img: "https://www.achencybe.com/img/pc/detail/rim/6_00_2.jpg",
                title: "car_list.rim.show_page.items.item2.title",
                content:"car_list.rim.show_page.items.item2.content",
                a: "",
                price: res.data.listAccessoriesList[2].partsPrice,
                price_dollar: res.data.listAccessoriesList[2].partsPriceDollar,
                priceUnit:"起"
              }
            ];
          }
        })
        .catch((err) => {
          console.log("getCarDetail", err);
        });
    },
  },
};
</script>
