var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "top-box" }, [
      _c(
        "div",
        {
          staticClass: "title-list",
          style: { marginBottom: _vm.isRim ? "110px" : "" },
        },
        _vm._l(_vm.titleList, function (item, idx) {
          return _c("div", { key: idx, staticClass: "title-item" }, [
            _c("img", { attrs: { src: item.img, alt: "" } }),
            _c("h2", { staticStyle: { "white-space": "nowrap" } }, [
              _vm._v(" " + _vm._s(_vm.$t(item.title)) + " "),
            ]),
            _c("div", { staticStyle: { height: "168px" } }, [
              _c("p", [_vm._v(" " + _vm._s(_vm.$t(item.content)) + " ")]),
            ]),
            _vm.language === "zh"
              ? _c(
                  "div",
                  {
                    staticClass: "price-box",
                    style: { marginTop: item.a ? "" : "-2px" },
                  },
                  [
                    _vm._v(" RMB"),
                    _c(
                      "span",
                      { staticStyle: { "font-family": "'ELEMENT-ICONS'" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.price === 0
                                ? "待公告"
                                : _vm.formatPrice(item.price)
                            )
                        ),
                        item.priceUnit
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "20px",
                                  "margin-left": "2px",
                                  "font-weight": "bold",
                                },
                              },
                              [_vm._v(_vm._s(item.priceUnit))]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                )
              : _c(
                  "div",
                  {
                    staticClass: "price-box",
                    style: { marginTop: item.a ? "" : "-2px" },
                  },
                  [
                    item.priceUnit
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "20px",
                              "margin-left": "2px",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v(_vm._s("From ") + " ")]
                        )
                      : _vm._e(),
                    _vm._v(" $"),
                    _c(
                      "span",
                      { staticStyle: { "font-family": "'ELEMENT-ICONS'" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.price_dollar === 0
                                ? "待公告"
                                : _vm.formatPrice(item.price_dollar)
                            )
                        ),
                      ]
                    ),
                  ]
                ),
          ])
        }),
        0
      ),
      _vm.isRim === true
        ? _c("div", { staticClass: "rim-split" }, [
            _c(
              "h2",
              {
                staticStyle: {
                  "white-space": "nowrap",
                  height: "93px",
                  "line-height": "93px",
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("car_list.rim.show_page.painting.title")) +
                    " "
                ),
              ]
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "list-con",
          style: { marginTop: _vm.isRim ? "15px" : "75px" },
        },
        _vm._l(_vm.list, function (item, idx) {
          return _c("div", { key: idx, staticClass: "list-item" }, [
            _c("img", { attrs: { src: item.img, alt: "" } }),
            typeof item.color !== "string"
              ? _c("div", { staticStyle: { "white-space": "nowrap" } }, [
                  _c("span", {
                    staticClass: "color-radio",
                    style: _vm.fixTwoColor(item.color[0], item.color[1]),
                  }),
                  _vm._v(_vm._s(_vm.$t(item.title)) + " "),
                ])
              : typeof item.color === "string"
              ? _c("div", { staticStyle: { "white-space": "nowrap" } }, [
                  _c("span", {
                    staticClass: "color-radio",
                    style: { background: item.color },
                  }),
                  _vm._v(_vm._s(_vm.$t(item.title)) + " "),
                ])
              : _vm._e(),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }